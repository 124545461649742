import { graphql, Link } from 'gatsby';
import React, { useEffect, useState } from "react";
import { isSafari } from 'react-device-detect';
import Slider from "react-slick";

import SEO from "../components/seo"
import Layout from "../components/layout"

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import HomeIcon from '@material-ui/icons/Home';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
// import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import "./contact.scss";

let eventAdded = false;
let type = "";
let initialSettings = {
    type: "initial",
    infinite: true,
    speed: 2000,
    slidesToShow: 10,
    slidesToScroll: 10,
    autoplay: true,
    autoplaySpeed: 6000,
};

const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
}

export default ({ data }) => {
    const [settings, setSettings] = useState(initialSettings);
    const [partners, setPartners] = useState(null);
    const partnersData = data.allDataJson.edges[0].node.partners;
    let nieuwsbriefTitle = '';
    let nieuwsbriefSubtitle = '';

    const updateSettings = () => {
        if (type !== "mobile" && window.innerWidth < 481) {
            type = "mobile";
            initialSettings = {
                infinite: true,
                speed: 2000,
                slidesToShow: 3,
                slidesToScroll: 3,
                autoplay: true,
                autoplaySpeed: 6000,
            };
            setSettings(initialSettings);
        } else if (type !== "small-tablet" && window.innerWidth > 480 && window.innerWidth < 701) {
            type = "small-tablet";
            initialSettings = {
                infinite: true,
                speed: 2000,
                slidesToShow: 4,
                slidesToScroll: 4,
                autoplay: true,
                autoplaySpeed: 6000,
            };
            setSettings(initialSettings);
        } else if (type !== "tablet" && window.innerWidth > 700 && window.innerWidth < 1200) {
            type = "tablet";
            initialSettings = {
                infinite: true,
                speed: 2000,
                slidesToShow: 6,
                slidesToScroll: 6,
                autoplay: true,
                autoplaySpeed: 6000,
            };
            setSettings(initialSettings);
        } else if (type !== "initial" && window.innerWidth > 1199) {
            type = "initial";
            initialSettings = {
                type: "initial",
                infinite: true,
                speed: 2000,
                slidesToShow: 10,
                slidesToScroll: 10,
                autoplay: true,
                autoplaySpeed: 6000,
            };
            setSettings(initialSettings);
        }
    }

    if (typeof window !== "undefined") {
        if (eventAdded === false) {
            window.addEventListener('resize', updateSettings);
            eventAdded = true;
        }
    }

    if (data.gcms && data.gcms.titles && data.gcms.titles.length > 0) {
        for (const title of data.gcms.titles) {
            if (title.indicator === 'nieuwsbrief-title' && title.value) {
                nieuwsbriefTitle = title.value;
            }
            if (title.indicator === 'nieuwsbrief-subtitle' && title.value) {
                nieuwsbriefSubtitle = title.value;
            }
        }
    }

    let metaDescriptionValue = '';
    if (data.gcms && data.gcms.metaDescriptions && data.gcms.metaDescriptions.length > 0) {
        for (const metaDescription of data.gcms.metaDescriptions) {
            if (metaDescription.indicator === 'contact-meta-description') {
                metaDescriptionValue = metaDescription.value;
            }
        }
    }

    useEffect(() => {
        updateSettings();
        if (isSafari === true) {
            setPartners(shuffleArray(partnersData).map((logo) => {
                return (
                    <a className={logo.class} key={logo.name} href={logo.site} rel="noopener noreferrer" target="_blank">
                        <img src={"https://media.travelspirit.nl" + logo.url.replace('webp', 'png').replace('/carousel/smw/', '/carousel/sm/')} alt={"carousel-image-" + logo.name} loading="lazy" />
                    </a>
                );
            }));
        } else {
            setPartners(shuffleArray(partnersData).map((logo) => {
                return (
                    <a className={logo.class} key={logo.name} href={logo.site} rel="noopener noreferrer" target="_blank">
                        <img src={"https://media.travelspirit.nl" + logo.url} alt={"carousel-image-" + logo.name} loading="lazy" />
                    </a>
                );
            }));
        }

        return function cleanup() {
            window.removeEventListener('resize', updateSettings);
            eventAdded = false;
        }
    }, [partnersData]);

    return (
        <Layout>
            <SEO title="Contact met TravelSpirit" description={metaDescriptionValue} urlParams="contact" />
            <img className="print print-image" src={isSafari === true ? 'https://media.travelspirit.nl/original/sm/TravelSpirit_Logo-FC.png' : 'https://media.travelspirit.nl/original/smw/TravelSpirit_Logo-FC.webp'} alt="logo" loading="lazy" />

            <div className="contact-page-container">
                <div className="contact-container">
                    <h1>Contact met TravelSpirit</h1>
                    <p className="subtitle-text">Hoe kunnen we je helpen?</p>

                    {type !== "mobile" && <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2>Algemene vraag</h2>
                                    <div className="text-container">
                                        <p className="bigger-text">Fijn dat je contact met ons overweegt!</p>
                                        <p>We leren je graag kennen. Tijdens een vrijblijvend kennismakingsgesprek bespreken we de mogelijkheden van onze travelsoftware voor jouw bedrijf.</p>
                                    </div>
                                    <Link id="meet-contact-button" className="header-links" to="/demo-afspraak">Plan een gesprek in</Link>
                                    <p className="small-text button-text empty-row">Stel online al jouw vragen</p>
                                    <p className="bold-title"><b>Korte vraag?</b></p>
                                    <p>Mail ons op <a href="mailto:info@travelspirit.nl">info@travelspirit.nl</a></p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-5">
                                    <h2>Contactgegevens</h2>
                                    <p className="empty-row contact-paragraph contact-address"><HomeIcon />Nieuw Baarnstraat 1, 3743 BN, Baarn</p>
                                    <p className="empty-row contact-paragraph"><EmailOutlinedIcon /> <a href="mailto:info@travelspirit.nl">info@travelspirit.nl</a></p>
                                    {/* <p className="empty-row contact-paragraph"><InfoOutlinedIcon /> info.travelspirit.nl</p> */}
                                </div>
                                <div className="col-md-7">
                                    {/* <img src="https://media.travelspirit.nl/original/lg/Google-Maps-Contact.jpg" alt="maps" loading="lazy" /> */}
                                    <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d156459.54845132763!2d5.159555045508919!3d52.21285439999999!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3ac9bfc8ca9b2bc7!2sTravelSpirit%20B.V.%20(SiteSpirit)!5e0!3m2!1snl!2snl!4v1626187436385!5m2!1snl!2snl" width="100%" height="300" frameBorder="0" loading="lazy"></iframe>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2>Support</h2>
                                    <div className="text-container">
                                        <p className="bigger-text">Voor bestaande klanten</p>
                                        <p>Optimale support, daar kan bij TravelSpirit op worden gerekend! Plan een gesprek in, indien je iemand wilt spreken, of wanneer het handig is dat we meekijken op het scherm.</p>
                                    </div>
                                    <Link id="support-contact-button" className="header-links" to="/support">Plan support afspraak in</Link>
                                    <p className="small-text button-text">We staan voor je klaar</p>
                                    <p className="bold-title"><b>Korte vraag?</b></p>
                                    <p>Mail ons op <a href="mailto:info@travelspirit.nl">info@travelspirit.nl</a></p>
                                    <p className="bold-title"><b>Actuele storingen en releases</b></p>
                                    <p>Altijd direct op de hoogte van actuele storingen & aankomende release.</p>
                                    <p><a href="https://status.travelspirit.nl/" target="_blank" rel="noreferrer">Check pagina en meld je aan voor updates</a></p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <h2>Demo</h2>
                                    <div className="text-container">
                                        <p className="bigger-text">Ook voor bestaande klanten</p>
                                        <p>Tijdens een demo laten we zien wat onze travelsoftware voor jouw reisbedrijf kan betekenen. Via onze demopagina kun je jezelf aanmelden voor verschillende onderwerpen of een individuele demo inplannen.</p>
                                    </div>
                                    <Link id="demo-contact-button" className="header-links" to="/demo">Demo inplannen</Link>
                                    <p className="small-text button-text">Volg live een online sessie</p>
                                    <div className="nieuwsgierig-container">
                                        <p className="bigger-text">Nu al nieuwsgierig?</p>
                                        <p>Vraag eenvoudig de opname van recente demo's op en bekijk deze terug wanneer dit jou het beste uitkomt</p>
                                    </div>
                                    <Link className="header-links" key="header-links" to={`/demo-terugkijken-aanvragen`}>Ontvang opnames van recente demo's</Link>
                                </div>
                            </div>
                        </div>
                    </div>}
                    {type === "mobile" && <div>
                        <div className="row first-row">
                            <div className="col-md-12">
                                <h2>Algemene vraag</h2>
                                <div className="text-container">
                                    <p className="bigger-text">Fijn dat je contact met ons overweegt!</p>
                                    <p>We leren je graag kennen. Tijdens een vrijblijvend kennismakingsgesprek bespreken we de mogelijkheden van onze travelsoftware voor jouw bedrijf.</p>
                                </div>
                                <Link id="meet-contact-button" className="header-links" to="/demo-afspraak">Plan een gesprek in</Link>
                                <p className="small-text button-text empty-row">Stel online al jouw vragen</p>
                                <p className="bold-title"><b>Korte vraag?</b></p>
                                <p>Mail ons op <a href="mailto:info@travelspirit.nl">info@travelspirit.nl</a></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Support</h2>
                                <div className="text-container">
                                    <p className="bigger-text">Voor bestaande klanten</p>
                                    <p>Optimale support, daar kan bij TravelSpirit op worden gerekend! Plan een gesprek in, indien je iemand wilt spreken, of wanneer het handig is dat we meekijken op het scherm.</p>
                                </div>
                                <Link id="support-contact-button" className="header-links" to="/support">Plan support afspraak in</Link>
                                <p className="small-text button-text">We staan voor je klaar</p>
                                <p className="bold-title"><b>Korte vraag?</b></p>
                                <p>Mail ons op <a href="mailto:info@travelspirit.nl">info@travelspirit.nl</a></p>
                                <p className="bold-title"><b>Actuele storingen en releases</b></p>
                                <p>Altijd direct op de hoogte van actuele storingen & aankomende release.</p>
                                <p><a href="https://status.travelspirit.nl/" target="_blank" rel="noreferrer">Check pagina en meld je aan voor updates</a></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-5">
                                <h2>Contactgegevens</h2>
                                <p className="empty-row contact-paragraph"><HomeIcon />Nieuw Baarnstraat 1, 3743 BN, Baarn</p>
                                <p className="empty-row contact-paragraph"><EmailOutlinedIcon /> <a href="mailto:info@travelspirit.nl">info@travelspirit.nl</a></p>
                                {/* <p className="empty-row contact-paragraph"><InfoOutlinedIcon /> info.travelspirit.nl</p> */}
                            </div>
                            <div className="col-md-7">
                                {/* <img src="https://media.travelspirit.nl/original/lg/Google-Maps-Contact.jpg" alt="maps" loading="lazy" /> */}
                                <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d156459.54845132763!2d5.159555045508919!3d52.21285439999999!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3ac9bfc8ca9b2bc7!2sTravelSpirit%20B.V.%20(SiteSpirit)!5e0!3m2!1snl!2snl!4v1626187436385!5m2!1snl!2snl" width="100%" height="300" frameBorder="0" loading="lazy"></iframe>
                            </div>
                        </div>
                        <div className="row last-row">
                            <div className="col-md-12">
                                <h2>Demo</h2>
                                <div className="text-container">
                                    <p className="bigger-text">Ook voor bestaande klanten</p>
                                    <p>Tijdens een demo laten we zien wat onze travelsoftware voor jouw reisbedrijf kan betekenen. Via onze demopagina kun je jezelf aanmelden voor verschillende onderwerpen of een individuele demo inplannen.</p>
                                </div>
                                <Link id="demo-contact-button" className="header-links" to="/demo">Demo inplannen</Link>
                                <p className="small-text button-text">Volg live een online sessie</p>
                                <div className="nieuwsgierig-container">
                                    <p className="bigger-text">Nu al nieuwsgierig?</p>
                                    <p>Vraag eenvoudig de opname van recente demo's op en bekijk deze terug wanneer dit jou het beste uitkomt</p>
                                </div>
                                <Link className="header-links previous-demos-link" key="header-links-previous-demos-link" to={`/demo-terugkijken-aanvragen`}>Ontvang opnames van recente demo's</Link>
                            </div>
                        </div>
                    </div>}
                </div>
                <div className="contact-form-container">
                    <h2>{nieuwsbriefTitle}</h2>
                    <p>{nieuwsbriefSubtitle}</p>
                    {/* <div id='mpform1102' ></div> */}
                    <form id="form1102" encType="application/x-www-form-urlencoded" className="mpForm" method="post" action="https://m13.mailplus.nl/genericservice/code/servlet/Redirect">
                        <ul className="mpFormTable mpTwoColumnLayout">
                            <li id="CNT1156" className="mpQuestionTable  ">
                                <div className="mpFormLabel">
                                    <label className="descriptionLabel" htmlFor="field1156">E-mailadres</label>
                                    <span className="mandatorySign"> *</span>
                                </div>
                                <div className="mpFormField">
                                    <input type="text" id="field1156" name="field1156"></input>
                                    <div className="sublabel">Vul hier je e-mailadres in naam@bedrijf.nl</div>
                                </div>
                            </li>
                            <li id="CNT1162" className="mpQuestionTable  ">
                                <div className="mpLabelRow">
                                    <div className="submitCellSpacer">
                                        <span></span>
                                    </div>
                                    <div className="submitCell">
                                        <input value="Verzenden" className="submitButton" name="next" type="submit" id="field1162"></input>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <fieldset style={{ display: 'none' }}>
                            <input type="hidden" name="userId" value="2110488"></input>
                            <input type="hidden" name="formEncId" value="RpPvABh6UMD4wG9y9VHG"></input>
                            <input type="hidden" name="pagePosition" value="1"></input>
                            <input type="hidden" name="viewMode" value="STATICINTEGRATION"></input>
                            <input type="hidden" name="redir" value="formAdmin2"></input>
                            <input type="hidden" name="formLayout" value="N"></input>
                            <input type="hidden" name="errorText" value="Dit formulier kon niet verzonden worden om de volgende reden(en):"></input>
                        </fieldset>
                    </form>
                </div>
                <div className="partner-carousel container">
                    <div className="row">
                        <h1>Onze partners en koppelingen:</h1>
                    </div>
                    <div className="carousel">
                        <Slider {...settings}>
                            {partners}
                        </Slider>
                    </div>
                    <div className="row">
                        <div className="partners-reference-container">
                            <Link className="partners-reference" to="/partners">Bekijk alle partners en koppelingen
                                <div className="icon baseline">
                                    <ArrowRightAltIcon />
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query {
        gcms {
            metaDescriptions {
                indicator
                value
            }
            titles {
                indicator
                value
                richValue { html }
            }
        }
        allDataJson {
            edges {
                node {
                    partners {
                        class
                        url
                        name
                        site
                    }
                }
            }
        }
    }
`;